.mainNav {
  background: #1f1d4c;
  margin-bottom: 20px;
  z-index: 9999;
}

.footerLinks {
  border-right: 1px solid #29b9dd;
  height: 1.3em;
  margin-right: 0;
}

.footerLinks:last-child {
  border-right: none;
}

.imageItem {
  display: inline-block;
}

.imageItem span {
  color: #fff;
  font-weight: bold;
  margin-left: 10px;
  text-transform: uppercase;
  vertical-align: middle;
}

.footer {
  background: #1f1d4c;
  padding-top: 16px;
}

.linksContainer a {
  margin: 0 10px;
}

.instagram {
  margin-top: 2px !important;
}

.followUs {
  color: #fff;
}

.socialLinksContainer {
  margin-bottom: 5px;
}

.socialLinksContainer a {
  display: inline-block;
  margin: 0 10px;
  color: #fff;
}

.lozenge {
  z-index: 999;
  /* background: url(../../public/img/cloud-lines.svg) no-repeat right bottom
    #1f1d4c; */
    background: #1f1d4c;
  background-size: 50%;
  border-radius: 25px;
}
